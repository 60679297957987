import React, { Suspense, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Loading from "./Loading";
import { AuthContext } from "../../context/AuthContext";
import routes from "./config";

const IDSRouter = () => {
  const { loading, isAuthenticated } = useContext(AuthContext);

  //need the loading, it gives context time to decide
  if (loading) {
    return <Loading />;
  }

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  const renderRoute = (route, parentKey = "") => {
    const routeKey = parentKey ? `${parentKey}-${route.key}` : route.key;

    // if not authenicated, do not render any private routes
    if (route.privateRoute && !isAuthenticated) {
      return null; // Skip rendering this route
    }
    return (
      <Route
        key={routeKey}
        path={route.path}
        element={
          <Suspense fallback={<Loading />}>
            {route.privateRoute ? (
              <PrivateRoute allowedRoles={route.roles}>
                <route.element />
              </PrivateRoute>
            ) : (
              <route.element />
            )}
          </Suspense>
        }
      >
        {route.children?.map((childRoute) => renderRoute(childRoute, routeKey))}
      </Route>
    );
  };

  return (
    <Routes>
      {/* If user is authenticated, prevent access to login page */}
      {isAuthenticated && token && refreshToken ? (
        <Route path="/landing/login" element={<Navigate to="/" />} />
      ) : (
        // Otherwise, allow access to login page
        <Route path="*" element={<Navigate to="/landing/login" />} />
      )}
      {routes.map((route) => renderRoute(route))}
      {/* Catch-all route for undefined paths, redirect to not_found, replace url */}
      <Route path="*" element={<Navigate to="/not_found" replace />} />
    </Routes>
  );
};

export default IDSRouter;
