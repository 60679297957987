import React, { useState, useContext, useEffect } from "react";
import IDSRouter from "./components/routing/IDSRouter";
import { DarkModeContext } from "./context/DarkModeContext";
import { AuthContext } from "./context/AuthContext";
import lightTheme from "./lightTheme";
import darkTheme from "./darkTheme";
import { ThemeProvider } from "@mui/material/styles";
import useInactivityTimer from "./components/inactivity/useAutoTimeout";
import InactivityDialog from "./components/inactivity/Inactivity";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useInactivity } from "./context/InactivityPauseContext";

const App = () => {
  const { darkMode } = useContext(DarkModeContext);
  const { isAuthenticated, handleLogout, userAccess } = useContext(AuthContext);

  // Inactivity Timer
  const { pauseTimer } = useInactivity();
  const INACTIVITY_TIME = userAccess.isAdmin ? 1800 : 1200;
  const DIALOG_TIME = 60;
  // const INACTIVITY_TIME = 30;
  // const DIALOG_TIME = 10;
  const [showInactivityDialog, setShowInactivityDialog] = useState(false);
  const [timer, resetTimeout] = useInactivityTimer(
    INACTIVITY_TIME,
    DIALOG_TIME,
    showInactivityDialog,
    setShowInactivityDialog,
    isAuthenticated,
    pauseTimer,
    handleLogout
  );
  // console.log(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      // Reset the inactivity timer when the user is authenticated
      resetTimeout();
      setShowInactivityDialog(false); // Add this line to reset the dialog state
    } else {
      // Remove event listeners when the user logs out
      const events = ["load", "mousedown", "click", "scroll", "keypress"];
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
      setShowInactivityDialog(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <>
      <ThemeProvider theme={darkMode === true ? darkTheme : lightTheme}>
        <div style={{ float: "right", width: "100px" }}>
          {isAuthenticated && (
            <InactivityDialog
              showInactivityDialog={showInactivityDialog}
              setShowInactivityDialog={setShowInactivityDialog}
              timer={timer}
              resetTimeout={resetTimeout}
              logout={handleLogout}
            />
          )}
        </div>

        <IDSRouter />
      </ThemeProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick={true}
        stacked
      />
    </>
  );
};

export default App;
