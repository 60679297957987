import { lazy } from "react";

const ICAC = lazy(() => import("../icac/ICAC"));
const Home = lazy(() => import("../home/Home"));
const AboutUs = lazy(() => import("../about-us/AboutUs"));

const CaseReferrals = lazy(() => import("../case-referrals/CaseReferrals"));
const Reminders = lazy(() => import("../case-referrals/Reminders"));
const Reports = lazy(() => import("../case-referrals/Reports/CaseReports"));
const FileClassification = lazy(() =>
  import("../case-referrals/FileClassification/FileClassification")
);
const ChangePass = lazy(() => import("../user/ChangePass"));
const CTNotifications = lazy(() => import("../user/CTNotifications.js"));
const Deconflict = lazy(() =>
  import("../deconfliction/deconflict/ui/Deconflict")
);
const DeconResultsBase = lazy(() =>
  import("../deconfliction/decon-results/ui/DeconResultsBase")
);
const HistorySubscription = lazy(() =>
  import("../deconfliction/history-subscriptions/History_Subscription.js")
);
const Directory = lazy(() => import("../directory/Directory.js"));
const UserDownload = lazy(() => import("../downloads/UserDownload.js"));
const FileRepository = lazy(() => import("../file-repository/FileRepository"));
const FAQs = lazy(() => import("../help/FAQs"));
const HelpReportIssue = lazy(() => import("../help/HelpReportIssue"));
const HelpTraining = lazy(() => import("../help/HelpTraining"));
const InvestigatorSearch = lazy(() =>
  import("../investigator-search/InvestigatorSearch")
);
const Newsletter = lazy(() => import("../newsletter/Newsletter"));
const NotFound = lazy(() => import("../not-found/NotFound"));
const NotificationsLog = lazy(() =>
  import("../notifications/NotificationLog.js")
);
const ManageGroup = lazy(() => import("../manage/Groups/ManageGroup"));
const PerformanceMeasuresDashboard = lazy(() =>
  import("../performance-measures/dashboard/PerformanceMeasures")
);
const ManageUI = lazy(() => import("../performance-measures/manage/ManageUI"));
const ReportUI = lazy(() => import("../performance-measures/report/ReportUI"));
const ApproveMeasures = lazy(() =>
  import("../performance-measures/approve-measures/ApproveMeasures")
);
const ReportDetails = lazy(() =>
  import("../performance-measures/approve-measures/ReportDetails")
);
const OJJDPSub = lazy(() =>
  import("../performance-measures/OJJDPSub/OJJDPSub")
);
const EnterMeasuresUI = lazy(() =>
  import("../performance-measures/enter_measures/ui/EnterMeasuresUI")
);
const ManageUsers = lazy(() => import("../manage/Users/Manage"));
// const ToolsEXIFReaderUI = lazy(() =>
//   import("../tools/ExifReader/ui/ToolsEXIFReaderUI")
// );
const ToolsFileHasherUI = lazy(() =>
  import("../tools/fileAnalyzer/ui/ToolsFileAnalyzerUI.js")
);
const ToolsMemberSystems = lazy(() => import("../tools/ToolsMemberSystems"));
const ToolsUsefulLinks = lazy(() => import("../tools/ToolsUsefulLinks"));
const TwoFactorAuthentication = lazy(() =>
  import("../user/TwoFactorAuthentication")
);
const MyProfile = lazy(() => import("../user/MyProfile"));

const Landing = lazy(() => import("../landing/Landing"));
const AgreementText = lazy(() => import("../agreement-text/AgreementText"));
const Privacy = lazy(() => import("../landing/PrivacyPolicy.js"));
const SMS = lazy(() => import("../landing/SMSTerms.js"));
const LoginFlow = lazy(() => import("../login/LoginFlow"));
const Register = lazy(() => import("../register/Register"));
const ReportIssue = lazy(() => import("../report-issue/ReportIssue"));
const ResetPassword = lazy(() => import("../reset-password/ResetPassword"));
const Help = lazy(() => import("../landing/Help"));

const routes = [
  {
    key: "home_landing",
    path: "/",
    element: ICAC,
    privateRoute: true,
    roles: ["isUser"],
    children: [
      {
        key: "home",
        path: "/",
        element: Home,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "about_us",
        path: "about_us",
        element: AboutUs,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "case_referrals",
        path: "case_referrals/",
        element: CaseReferrals,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },
      {
        key: "case_reminders",
        path: "case_reminders",
        element: Reminders,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isCaseMgr"],
      },
      {
        key: "case_reports",
        path: "case_reports",
        element: Reports,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },
      {
        key: "user/change_pass",
        path: "user/change_pass",
        element: ChangePass,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "file_classification",
        path: "file_classification",
        element: FileClassification,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isCaseMgr"],
      },
      {
        key: "ct_notifications",
        path: "ct_notifications",
        element: CTNotifications,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "deconfliction/search",
        path: "deconfliction/search",
        element: Deconflict,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "deconfliction/results",
        path: "deconfliction/results",
        element: DeconResultsBase,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "deconfliction/history_subscriptions",
        path: "deconfliction/history_subscriptions",
        element: HistorySubscription,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "directory",
        path: "directory",
        element: Directory,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "downloads",
        path: "downloads",
        element: UserDownload,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isCaseUser", "isCaseMgr"],
      },
      {
        key: "file_repository",
        path: "file_repository",
        element: FileRepository,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "help/faqs",
        path: "help/faqs",
        element: FAQs,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "help/report_issue",
        path: "help/report_issue",
        element: HelpReportIssue,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "help/training",
        path: "help/training",
        element: HelpTraining,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "investigator_search",
        path: "investigator_search",
        element: InvestigatorSearch,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "newsletter",
        path: "newsletter",
        element: Newsletter,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "not_found",
        path: "not_found",
        element: NotFound,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "notifications_log",
        path: "notifications",
        element: NotificationsLog,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "managegroups",
        path: "managegroups",
        element: ManageGroup,
        privateRoute: true,
        roles: ["isAdmin", "isCommander"],
      },
      {
        key: "performance_measures",
        path: "performance_measures",
        element: PerformanceMeasuresDashboard,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isPmMgr", "isPMUser"],
      },
      {
        key: "pm_manage",
        path: "pm_manage",
        element: ManageUI,
        privateRoute: true,
        roles: ["isAdmin", "isCommander", "isPmMgr"],
      },
      {
        key: "pm_report",
        path: "pm_report",
        element: ReportUI,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isPmMgr", "isPMUser"],
      },
      {
        key: "pm_approve_measures",
        path: "pm_approve_measures",
        element: ApproveMeasures,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: "report_details/:slug",
        path: "pm_report_details/:slug",
        element: ReportDetails,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: "OJJDP-Submit",
        path: "pm_OJJDP-Submit",
        element: OJJDPSub,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isPmMgr"],
      },
      {
        key: "enter_measures",
        path: "pm_enter_measures",
        element: EnterMeasuresUI,
        privateRoute: true,
        roles: ["isCommander", "isAdmin", "isPmMgr", "isPMUser"],
      },
      {
        key: "manageusers",
        path: "manageusers",
        element: ManageUsers,
        privateRoute: true,
        roles: ["isCommander", "isAdmin"],
      },
      // {
      //   key: "tools/exif_reader",
      //   path: "tools/exif_reader",
      //   element: ToolsEXIFReaderUI,
      //   privateRoute: true,
      //   roles: ["isUser"],
      // },
      {
        key: "tools/file_analyzer",
        path: "tools/file_analyzer",
        element: ToolsFileHasherUI,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "tools/member_systems",
        path: "tools/member_systems",
        element: ToolsMemberSystems,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "tools/useful_links",
        path: "tools/useful_links",
        element: ToolsUsefulLinks,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "two_factor",
        path: "two_factor",
        element: TwoFactorAuthentication,
        privateRoute: true,
        roles: ["isUser"],
      },
      {
        key: "my_profile",
        path: "my_profile",
        element: MyProfile,
        privateRoute: true,
        roles: ["isUser"],
      },
    ],
  },
  {
    key: "landing",
    path: "/landing/",
    element: Landing,
    privateRoute: false,
    children: [
      {
        key: "agreement_text",
        path: "agreement_text",
        element: AgreementText,
        privateRoute: false,
      },
      {
        key: "login",
        path: "login",
        element: LoginFlow,
        privateRoute: false,
      },
      {
        key: "register",
        path: "register",
        element: Register,
        privateRoute: false,
      },
      {
        key: "report_issue",
        path: "report_issue",
        element: ReportIssue,
        privateRoute: false,
      },
      {
        key: "reset_password",
        path: "reset_password",
        element: ResetPassword,
        privateRoute: false,
      },
      {
        key: "help",
        path: "help",
        element: Help,
        privateRoute: false,
      },
      {
        key: "sms_terms",
        path: "sms_terms",
        element: SMS,
        privateRoute: false,
      },
      {
        key: "privacy_policy",
        path: "privacy_policy",
        element: Privacy,
        privateRoute: false,
      },
      {
        key: "not_found",
        path: "not_found",
        element: NotFound,
        privateRoute: false,
      },
    ],
  },
];

export default routes;
