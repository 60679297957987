import { createContext, useContext, useEffect, useState } from "react";

const InactivityContext = createContext();
const channel = new BroadcastChannel("inactivity_timer");

export const InactivityProvider = ({ children }) => {
  const [pauseTimer, setPauseTimer] = useState(false);

  useEffect(() => {
    channel.onmessage = (message) => {
      if (message.data.type === "PAUSE_TIMER_UPDATE") {
        console.log(
          "Received PAUSE_TIMER_UPDATE from another tab:",
          message.data.payload
        );
        setPauseTimer(message.data.payload);
      }
    };

    return () => {
      channel.close();
    };
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  // **Rebroadcast Pause Timer Every 5 Minutes**
  useEffect(() => {
    let interval;
    if (pauseTimer) {
      interval = setInterval(() => {
        console.log("Rebroadcasting PAUSE_TIMER_UPDATE:", pauseTimer);
        channel.postMessage({
          type: "PAUSE_TIMER_UPDATE",
          payload: pauseTimer,
        });
      }, 300000); // Every 5 minutes
    }

    return () => clearInterval(interval);
  }, [pauseTimer]); // Only runs when `pauseTimer` changes

  // Broadcast pauseTimer state change
  const updatePauseTimer = (newState) => {
    console.log("Broadcasting PAUSE_TIMER_UPDATE:", newState);
    setPauseTimer(newState);
    channel.postMessage({ type: "PAUSE_TIMER_UPDATE", payload: newState });
  };

  return (
    <InactivityContext.Provider
      value={{ pauseTimer, setPauseTimer: updatePauseTimer }}
    >
      {children}
    </InactivityContext.Provider>
  );
};

export const useInactivity = () => useContext(InactivityContext);
