import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const PrivateRoute = ({ children, allowedRoles }) => {
  const { userAccess, isAuthenticated } = useContext(AuthContext);

  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  // Get the user's roles that are true
  const userRoles = Object.keys(userAccess).filter((key) => userAccess[key]);

  // Check if user has one of the allowed roles - just one will do!
  const hasAccess = allowedRoles.some((role) => userRoles.includes(role));

  if (!isAuthenticated || !token || !refreshToken) {
    //GET OUTTA HERE, you're not logged in
    return <Navigate to="/landing/login" replace />;
  }
  if (!hasAccess) {
    // Redirect, authenticated but they don't have the right role!
    console.log("Redirecting to /not_found");

    return <Navigate to="/not_found" replace />;
  }

  // Render if allowed yayyyyyyy
  return children;
};

export default PrivateRoute;
