import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Paper from "@mui/material/Paper";
import App from "./App";
import "./index.css";
import { DarkModeContextProvider } from "./context/DarkModeContext";
import { AuthContextProvider } from "./context/AuthContext";
import { DemoModeContextProvider } from "./context/DemoModeContext";
import reportWebVitalsToGA from "./assets/utils/analytics/reportWebVitals";
import { InactivityProvider } from "./context/InactivityPauseContext";

createRoot(document.getElementById("root")).render(
  <BrowserRouter
    future={{
      v7_relativeSplatPath: true,
      v7_startTransition: true,
    }}
  >
    {" "}
    <AuthContextProvider>
      <Paper>
        <DemoModeContextProvider>
          <DarkModeContextProvider>
            <InactivityProvider>
              <App />
            </InactivityProvider>
          </DarkModeContextProvider>
        </DemoModeContextProvider>
      </Paper>
    </AuthContextProvider>{" "}
  </BrowserRouter>
);

// Start measuring performance and send data to Google Analytics
reportWebVitalsToGA();
